<template>
  <header id="h">
    <b-navbar toggleable="lg" class="mainMenu" id="n" >
      <b-navbar-brand>
        <div class="logo" id="l">
          <b-link :to="{ name : 'HomeLang', params: {lang : $root.lang.shortName}}">
            <video 
              loop 
              muted 
              inline 
              contextmenu="return false" 
              :poster="require('@/assets/dmn-logo.svg')" id="logoVideo"
              @mouseover="playVideo" @focus="playVideo" @mouseout="stopVideo" @blur="stopVideo">
                <source :src="require('@/assets/dmn-logo-anim.mp4')" type="video/mp4">
            </video>
            <!-- <img :src="require('@/assets/img/logo-part-1.jpg')" alt="DMN logo"> -->
          </b-link>
        </div>
        <div id="t">
          <b-link :to="{ name : 'HomeLang', params: {lang : $root.lang.shortName}}">
            <img :src="require('@/assets/img/logo-part-2.png')" alt="DMN logo" class="img-fluid d-block"> 
          </b-link>
        </div>
      </b-navbar-brand>
      <b-navbar-toggle target="main-menu" id="main-menu-button"></b-navbar-toggle>
      <b-collapse id="main-menu" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item v-for="p in menu.pages" :key="p.id">
            <span v-if="p.url !== ''">
              <b-link :to="'/' + p.language.shortName + '/' + p.url">{{ p.title | removeLat }}</b-link>
            </span>
            <span v-else>
             <b-link :to="{ name : 'HomeLang', params: {lang : $root.lang.shortName}}">{{ p.title | removeLat }}</b-link>
            </span>
          </b-nav-item>
          <HeaderSearch />
          <b-nav-item class="lgn">
            <LocaleChooser :lang="_lang" />
          </b-nav-item>
          <template v-if="user">
            <b-nav-item class="lgn">
              <b-dropdown variant="link" right no-caret class="profileDropdown" :title="'Sesija ističe u: ' + this.getAuthData().accessTokenExpire">
                <template #button-content>
                  <b-avatar :src="user.logo" size="2.4rem"/> {{ user.enterpriseShortName | removeLat}}
                  <svg class="car" style="margin-top: -2px; margin-left: 5px;" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 1L6 6L1 1" stroke="#35C0C0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </template>
                <b-dropdown-item :to="{name : 'Dashboard'}">{{ $t('signin.dashboard') }}</b-dropdown-item>
                <b-dropdown-item :to="{name: 'Profile'}">{{ $t('signin.profile') }}</b-dropdown-item>
                <b-dropdown-item @click="logout">{{ $t('signin.logout') }}</b-dropdown-item>
              </b-dropdown>
            </b-nav-item>
          </template>
            <template v-if="!user">
            <b-nav-item class="lgn">
              <b-button v-b-modal.loginModal variant="outline-primary">{{ $t('signin.login') }}</b-button>
            </b-nav-item>
            </template>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <!-- Login modal -->
    <b-modal id="loginModal" centered title="" hide-footer>
      <b-row>
        <b-col md="8" offset-md="2">
          <p style="text-align:center;" class="mx-5">
            <img src="@/assets/img/dmn-logo.png" class="img-fluid" style="max-width: 188px;">
          </p>
          <h3 class="my-4">{{ $t('signin.member-login') }}</h3>
          <b-alert :show="alertShow" variant="danger">{{loginError.message}}</b-alert>
          <b-form @submit="memberLogin">
          <div class="form-group">
            <b-form-input v-model="email" :disabled="showSpinner" size="lg" type="text" :placeholder="$t('signin.enter-email')" />
          </div>
          <div class="form-group">
            <b-form-input v-model="password" :disabled="showSpinner" size="lg" type="password" :placeholder="$t('signin.enter-password')"/>
          </div>
          <div class="form-group text-center">
            <b-overlay :show="showSpinner" spinner-small spinner-variant="secondary" opacity="0.5">
              <button :disabled="showSpinner" type="submit" class="btn btn-primary btn-lg">{{ $t("signin.login") }}</button>
            </b-overlay>
          </div>
          </b-form>
          <p class="text-center">
            <b-link to="" @click="forgotPassword">{{ $t('signin.forgotPassword') }}</b-link>
          </p>
          </b-col>
        </b-row>
     </b-modal>
  </header>
</template>

<script>
import LocaleChooser from "@/components/util/LocaleChooser.vue"
import HeaderSearch from "@/components/util/HeaderSearch.vue"
import { BAlert, BAvatar, BLink } from 'bootstrap-vue'

export default {
  name: 'Header',
  components: { LocaleChooser, BAlert, BLink, BAvatar, HeaderSearch},
  data: function() {
    return {
      menu: {},
      loginError : "",
      email : "",
      password: "",
      alertShow : false,
      showSpinner: false,
      showLoginModal: false,
      infoMessage: null,
      infoData: {}
    }
  },

  props: {
    user: Object
  },

  computed: {
    _lang: function() {
      return this.$root.lang
    }
  },

  created: async function() {
    // Preuzimanje menija headera za odgovarajući jezik
    await this.$http.get("/api/page/get/menu/header/" + this.$root.lang.IANA).then(r => {
      this.menu = r.data;
    }).catch(e => {});
    window.addEventListener('scroll', this.handleScroll);
    this.getUserData();
  },

  watch: {
   _lang : async function(newLang, oldLang) {
      await this.$http.get("/api/page/get/menu/header/" + this._lang.IANA).then(r => {
        this.menu = r.data;
      }).catch(e => {})
    }
  },

  mounted: function() {
    // Ripple efekat na logou
    //window.$(".logo").ripples({ resolution: 50, perturbance: 0.030});
  },

  methods: {
    memberLogin: function (event) {
      event.preventDefault();
      this.showSpinner = true;
      // console.log(this.password);
      // console.log(this.email);
      this.$http.post('/api/login', { email : this.email, password: this.password }).then(r => {
        if (r.headers.authorization !== null) {
          window.localStorage.setItem("AccessToken", r.headers.authorization);
          window.localStorage.setItem("Email", 	r.headers.email);
          window.localStorage.setItem("AccessTokenExpire", r.headers.token_expiration_time);
          this.getUserData();
          this.$bvModal.hide("loginModal");
          this.$router.push({name : "Dashboard"});
        }
       }).catch(e => { this.handleError(e); })
      this.showSpinner = false; 
    },

    getUserData: function() {
      if (this.getAuthData() !== false) {
        if (window.localStorage.getItem("dmn_user") === null) {
          //console.log("dmn_user ne postoji. Preuzimam podatke iz baze.");
          this.$http.get('/cms/api/users/get/email/' + this.getAuthData().email + "/" + this.$root.lang.IANA, { headers: {Authorization: this.getAuthData().accessToken}}).then(r => {
            window.localStorage.setItem("dmn_user", this.encodeJSON(r.data));
            this.$root.user = r.data;
          }).catch(e => {
            this.handleError(e);
          })
        } else {
          //console.log("dmn_user postoji");
          // console.log(window.localStorage.getItem("dmn_user"));
          this.$root.user = this.decodeJSON(window.localStorage.getItem("dmn_user"));
        }
      } 
      this.showSpinner = false;
    },

    logout() {
      // console.log("Kliknuto na logout");
      this.removeAuthData();
      window.location = "/";
    },
   
    forgotPassword(e) {
      e.preventDefault();
      this.$bvModal.hide("loginModal");
      this.$router.push({name : "ForgotPassword", params: {lang : this.$root.lang.shortName}});
    },

    handleScroll: function(event) {
      var scrollPx = document.documentElement.scrollTop || document.body.scrollTop;
      var headerHeight = document.getElementById("h").height;
      var headerElem = document.getElementById("h");
      var logoRipple = document.getElementById("l");
      var logoText = document.getElementById("t");
      var navbar = document.getElementById("n");
      const logoVideo = document.getElementById("logoVideo");
      // console.log(logoRipple);
      // console.log(logoRipple.firstElementChild);
      // console.log(window.innerWidth);

      if (window.innerWidth > 460) {
        if (scrollPx > 140) {
          headerElem.style.height = "72px";
          // logoText.style.marginLeft = "80px"; logoText.style.marginTop = "-60px";
          logoText.style.width = "115px"; logoText.style.height = "60px"; 
          logoRipple.style.width = "66px"; logoRipple.style.height = "60px";
          //logoRipple.firstChild.style.width = "66px"; logoRipple.firstChild.style.height = "60px";
          logoVideo.style.width = "66px"; logoVideo.style.height = "66px";
          navbar.style.paddingTop = "0px"; navbar.style.paddingBottom = "0px"; 
        } else {  
          headerElem.style.height = "140px";
          // logoText.style.marginLeft = "120px"; logoText.style.marginTop = "-93px";
          logoText.style.width = "175px"; logoText.style.height = "93px"; 
          logoRipple.style.width = "102px"; logoRipple.style.height = "93px";
          // logoRipple.firstChild.style.width = "102px"; logoRipple.firstChild.style.height = "93px";
          logoVideo.style.width = "102px"; logoVideo.style.height = "102px";
          navbar.style.paddingTop = "1rem"; navbar.style.paddingBottom = "1rem"; 
          
        }
      }
      // window.$("#l").ripples({ resolution: 50, perturbance: 0.030});
    },

    playVideo(event) {
      //console.log("Prikaz videa")
      event.target.play()
      
    },

    stopVideo(event) {
      //console.log("Skrivanje videa")
      event.target.pause()
      event.target.load();
    }
  }
}
</script>

<style>
  .spinner-border {width: 1.5rem; height: 1.5rem; border: 0.2rem solid currentColor; border-right-color: transparent; animation: spinner-border .9s linear infinite;}
</style>
