<template>
  <b-nav-text class="lgn position-relative"  >
    <b-img :src="require('@/assets/img/search-button.svg')" id="shb" @click="showSearchBar" />
    <div id="sih">
      <b-input id="searchHeader" :placeholder="$t('search.search')"  @input="searchHeaderType()" v-model="searchTerm" autocomplete="new-password"> </b-input>
      <span id="sbx" @click="closeSearchBar()">
        <BIconX font-scale="2"></BIconX>
      </span>
      <div id="shr">
        <div v-if="searchMedia" @click="closeSearchResults">
            <ul class="item" v-for="(m, index) in searchMedia" :key="m.id">
             
              <li v-if="$root.lang.shortName=='en'" class="mediaTitle">
                <div class="row">
                 <div class="col-1">
                  <span v-if="m.mimeType.indexOf('video') !== -1">
                    <CustomVideoIcon width="24" height="24" class="me-2"/>
                  </span>
                  <span v-if="m.mimeType.indexOf('image') !== -1">
                    <CustomImageIcon width="24" height="24" class="me-2"/>
                  </span>
                   <span v-if="m.mimeType.indexOf('audio') !== -1">
                    <CustomAudioIcon width="20" height="24" class="me-2"/>
                  </span>
                  </div>
                  <div class="col-11">
                     <router-link :to="myLinks[index]">
                    {{ m.titleEn | removeLat }} 
                    </router-link>
                  </div>
                </div>
              </li>
              <li v-else  class="mediaTitle">
                <div class="row">
                 <div class="col-1">
                  <span v-if="m.mimeType.indexOf('video') !== -1">
                    <CustomVideoIcon width="24" height="24" class="me-2"/>
                  </span>
                  <span v-if="m.mimeType.indexOf('image') !== -1">
                    <CustomImageIcon width="24" height="24" class="me-2"/>
                  </span>
                   <span v-if="m.mimeType.indexOf('audio') !== -1">
                    <CustomAudioIcon width="20" height="24" class="me-2"/>
                  </span>
                  </div>
                  <div class="col-11">
                     <router-link :to="myLinks[index]">
                    {{ m.title | removeLat }} 
                    </router-link>
                  </div>
                </div>
              </li>
            </ul>
        </div>
        <div v-if="noResult" class="text-center p-3" style="color:#989EB0">
          {{ $t('search.no-result') }}
        </div>
      </div>
    </div>
  </b-nav-text>
</template>

<script>
/**
 * shb - search header button
 * sbx - search button x
 * shr - search header results
 */
import { BIconX, BImg } from 'bootstrap-vue'
import CustomVideoIcon from '@/components/util/icons/video';
import CustomImageIcon from '@/components/util/icons/image';
import CustomAudioIcon from '@/components/util/icons/audio';
export default {
  name: "HeaderSearch",
  components: { BIconX, BImg, CustomVideoIcon, CustomImageIcon, CustomAudioIcon },
  data() {
    return {
      searchTerm: "",
      searchMedia: [], 
      noResult: false
    }
  },
  methods: {
    showSearchBar() {
      document.getElementById("shb").style.opacity = 0;
      document.getElementById("searchHeader").style.opacity = 1;
      if (window.innerWidth < 992) {
        document.getElementById("searchHeader").style.width = "100%";
        document.getElementById("searchHeader").style.left = "0px";
        document.getElementById("sbx").style.left = "89%";
      } else {
        document.getElementById("searchHeader").style.width = "420px";
        document.getElementById("searchHeader").style.left = "-375px";
      }
      document.getElementById("searchHeader").style.boxShadow = "0 0 0 0.5rem rgba(154, 220, 218, 0.5)";
      document.getElementById("searchHeader").style.padding = "0.375rem 1.05rem";
      document.getElementById("sbx").style.opacity = "1";
      document.getElementById("sbx").style.zIndex = "10";
    },
    
    closeSearchBar (e) {
      document.getElementById("sbx").style.opacity = "0";
      document.getElementById("sbx").style.zIndex = "9";
      document.getElementById("searchHeader").style.opacity = "0";
      document.getElementById("searchHeader").style.width = "0px";
      document.getElementById("searchHeader").style.left = "30px";
      document.getElementById("searchHeader").style.boxShadow = "none";
      document.getElementById("searchHeader").style.padding = "0";
      document.getElementById("shr").style.display = "none";
      document.getElementById("shb").style.opacity = 1;
      this.searchTerm = "";
      if (window.innerWidth < 992) {
        this.$root.$emit('bv::toggle::collapse', 'main-menu');
      }
      this.noResult = false;
    },

    closeSearchResults() {
      this.closeSearchBar();
      document.getElementById("shr").style.display = "none";
      this.searchTerm = "";
    },

    async searchHeaderType() {
      // console.log(this.searchTerm);
      const sort = "createdAt,desc";
      this.searchMedia = [];
      const filter = {
        keyword : this.searchTerm,
        type: "all",
        category: [],
        displayLang: this.$root.lang.IANA,
        year: [],
        language: []
      };

      await this.$http.post("/api/media/s?sort=" + sort, filter).then((r) => {
        // console.log(r);
        document.getElementById("shr").style.display = "block";
        this.noResult = !r.data.hasContent;
        // Prikazujemo do 7 rezultata
        if (r.data.content.length >= 5) {
          this.searchMedia = r.data.content.slice(0, 7);
        } else {
          this.searchMedia = r.data.content;
        }
      }).catch((e) => { console.log(e) });
    }
    
  },
  computed: {
    myLinks : function() {
      var linkz = []
      for (var i = 0; i < this.searchMedia.length; i++) {
        const m = this.searchMedia[i];
        var link = "";
        if (typeof m.mimeType !== "undefined") {
          if (m.mimeType.indexOf("image") >= 0) {
            link = { name: 'Image', params : { lang: this.$root.lang.shortName, imageId: m.id } };
          } else if (m.mimeType.indexOf("video") >= 0) {
            link = { name: 'Video', params : { lang: this.$root.lang.shortName, videoId: m.id } };
          } else { 
            link = { name: 'SingleAudio', params : { lang: this.$root.lang.shortName, audioId: m.id } };
          }
          linkz.push(link);
        }
      }
      return linkz;
    }
  }

}
</script>

<style>
</style>
