<template>
  <svg :width="width" :height="height" 
    viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M64 8V64H8V8H64ZM64 0H8C3.6 0 0 3.6 0 8V64C0 68.4 3.6 72 8 72H64C68.4 72 72 68.4 72 64V8C72 3.6 68.4 0 64 0Z" fill="#9ADCDA"/>
    <path d="M24 40.5L12 56H60L44.5 35.5L32.5 51L24 40.5Z" fill="#005B70"/>
  </svg>
</template>

<script>
export default {
  name: "CustomImageIcon",
  props: {
    width: String,
    height: String
  }
}
</script>
<style></style>
