import Vue from "vue"
import VueI18n from "vue-i18n"

Vue.use(VueI18n)

const dateTimeFormats = {
  'en-GB': {
    short: {
      year: 'numeric', 
      month: 'short', 
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
    long: {
      year: 'numeric', 
      month: 'long', 
      day: 'numeric',
      weekday: 'long', 
      hour: 'numeric', 
      minute: 'numeric'
    }
  },
  'sr-Latn': {
    short: {
      year: 'numeric', 
      month: 'long', 
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    },
    long: {
      year: 'numeric', 
      month: 'long', 
      day: '2-digit',
      weekday: 'long', 
      hour: '2-digit', 
      minute: '2-digit'
    }
  },
  'sr-Cyrl': {
    short: {
      year: 'numeric', 
      month: 'long', 
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    },
    long: {
      year: 'numeric', 
      month: 'long', 
      day: '2-digit',
      weekday: 'long', 
      hour: '2-digit', 
      minute: '2-digit'
    }
  }
}

export default new VueI18n({
  dateTimeFormats,
  locale: 'en-GB',
  fallbackLocale: 'en-GB',
  silentFallbackWarn : true
  //messages: loadLocaleMessages()
})
