import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  /* Prijava i administracija */
  /* Public */
  {
    path: '/', 
    name: 'Home', 
    component: () => import(/* webpackChunkName: "Pages" */ '@/views/Home.vue')
   },
  /* User stuff */
  { path: '/sign-up',                 name: 'SignUp', component: () => import(/* webpackChunkName: "Users" */ '@/components/public/user/SignUp.vue')},
  { path: '/sign-up/confirm/:hash',   name: 'SignUpConfirm', component: () => import(/* webpackChunkName: "Users" */ '@/components/public/user/SignUpConfirm.vue')},
  { path: '/login',                   name: 'Login2',    component: () => import(/* webpackChunkName: "Users" */ '@/components/admin/user/Login.vue')},
  { path: '/:lang/login/:message',    name: 'Login',    component: () => import(/* webpackChunkName: "Users" */ '@/components/admin/user/Login.vue')},
  { path: '/:lang/forgot-password',   name: 'ForgotPassword',     component: () => import(/* webpackChunkName: "Users" */ '@/components/public/user/ForgotPassword.vue') },
  { path: '/:lang',                   name: 'HomeLang',     component: () => import(/* webpackChunkName: "Pages" */ '@/views/Home.vue') },
  { path: '/:lang/reset-password/:hash',    name: 'ResetPasswordConfirm', component: () => import(/* webpackChunkName: "Users" */ '@/components/public/user/ResetPasswordConfirm.vue') },
  { path: '/:lang/reset-password/:hash/timed-out', name: 'ResetPasswordTimedOut', component: () => import(/* webpackChunkName: "Users" */ '@/components/public/user/ResetPasswordTimedOut.vue') },
  
  /* Custom contact page */
  { path: '/en/contact',            name: 'Contact',     component: () => import(/* webpackChunkName: "Pages" */ '@/views/Contact.vue') },
  { path: '/sr-lat/kontakt',        name: 'Kontakt_lat',     component: () => import(/* webpackChunkName: "Pages" */ '@/views/Contact.vue') },
  { path: '/sr-cir/kontakt',        name: 'Kontakt_ci',     component: () => import(/* webpackChunkName: "Pages" */ '@/views/Contact.vue') },

  /* Custom media page */
  { path: '/:lang/media', name: 'Media', component: () => import(/* webpackChunkName: "Pages" */ '@/views/Media.vue') },
  
  /* Category page */
  { path: '/:lang/category/:categoryId', name: "CategoryMedia", component: () => import(/* webpackChunkName: "Pages" */  '@/components/public/category/CategoryMedia.vue') },

  /* CMS */
  { path: '/cms/dashboard', name: 'Dashboard', component: () => import(/* webpackChunkName: "Admin" */ '@/components/admin/Dashboard.vue'), meta: "reqLogin"},

  /* Korisnici */
  /* CMS */
  { path: '/cms/profile', name: 'Profile', component: () => import(/* webpackChunkName: "Admin" */ '@/components/admin/user/profile/Profile.vue'), meta: "reqLogin" },
  { path: '/cms/profile/change-password/:username/', name: 'ChangePassword', component: () => import(/* webpackChunkName: "Admin" */ '@/components/admin/user/profile/ChangePassword.vue'), meta: "reqLogin" },
  { path: '/cms/users/', name: "Users", component: () => import(/* webpackChunkName: "Admin" */ '@/components/admin/user/AllUsers.vue'), meta: "reqLogin" },
  { path: '/cms/users/add-user', name: "AddUser", component: () => import(/* webpackChunkName: "Admin" */ '@/components/admin/user/AddUser.vue'), meta: "reqLogin" },
  { path: '/cms/users/disabled-users', name: "DisabledUsers", component: () => import(/* webpackChunkName: "Admin" */ '@/components/admin/user/DisabledUsers.vue'), meta: "reqLogin" },
  { path: '/cms/users/edit/:username/', name: "EditUser", component: () => import(/* webpackChunkName: "Admin" */ '@/components/admin/user/EditUser.vue'), meta: "reqLogin" },
  { path: '/cms/users/roles/', name: "Roles", component: () => import(/* webpackChunkName: "Admin" */ '@/components/admin/user/roles/Roles.vue'), meta: "reqLogin" },
  { path: '/cms/users/roles/add/', name: "AddUserRole", component: () => import(/* webpackChunkName: "Admin" */ '@/components/admin/user/roles/AddRole.vue'), meta: "reqLogin" },
  { path: '/cms/users/roles/edit/:roleId/', name: "EditUserRole", component: () => import(/* webpackChunkName: "Admin" */  '@/components/admin/user/roles/EditRole.vue'), meta: "reqLogin" },
  
  /* Public */
  { path: '/:lang/user/:userId', name: "UserMedia", component: () => import(/* webpackChunkName: "Pages" */  '@/components/public/user/UserMedia.vue')},

  /* Jezici */
  { path: '/cms/languages/', name: 'Languages', component: () => import(/* webpackChunkName: "Admin" */ '@/components/admin/langauge/AllLanguages.vue'), meta: "reqLogin" },
  { path: '/cms/languages/disabled', name: 'DisabledLanguages', component: () => import(/* webpackChunkName: "Admin" */ '@/components/admin/langauge/DisabledLanguages.vue'), meta: "reqLogin" },
  { path: '/cms/languages/add', name: 'AddLanguage', component: () => import(/* webpackChunkName: "Admin" */ '@/components/admin/langauge/AddLanguage.vue'), meta: "reqLogin" },
  { path: '/cms/languages/edit/:languageShortName', name: 'EditLanguage', component: () => import(/* webpackChunkName: "Admin" */ '@/components/admin/langauge/EditLanguage.vue'), meta: "reqLogin" },

  /* Stranice */
  { path: '/cms/pages/', name: 'Pages', component: () => import(/* webpackChunkName: "Pages" */ '@/components/admin/page/AllPages.vue'), meta: "reqLogin" },
  { path: '/cms/pages/add', name: 'AddPage', component: () => import(/* webpackChunkName: "Pages" */ '@/components/admin/page/AddPage.vue'), meta: "reqLogin" },
  { path: '/cms/pages/:languageShortName', name: 'PagesForLanguage', component: () => import(/* webpackChunkName: "Pages" */ '@/components/admin/page/AllPagesForLanguage.vue'), meta: "reqLogin" },
  { path: '/cms/pages/edit/:pageId', name: 'EditPage', component: () => import(/* webpackChunkName: "Pages" */ '@/components/admin/page/EditPage.vue'), meta: "reqLogin" },
  { path: '/cms/pages/removed/', name: 'DeletedPages', component: () => import(/* webpackChunkName: "Pages" */ '@/components/admin/page/DeletedPages.vue'), meta: "reqLogin" },
  { path: '/cms/pages/unpublished/', name: 'UnpublishedPages', component: () => import(/* webpackChunkName: "Pages" */ '@/components/admin/page/UnpublishedPages.vue'), meta: "reqLogin" },

  /* Menu */
  /* CMS */
  { path: '/cms/pages/menu', name: 'Menu', component: () => import(/* webpackChunkName: "Pages" */ '@/components/admin/page/menu/AllMenus.vue'), meta: "reqLogin" },
  { path: '/cms/pages/menu/:languageShortName', name: 'MenuForLanguage', component: () => import(/* webpackChunkName: "Pages" */ '@/components/admin/page/menu/MenuForLanguage.vue'), meta: "reqLogin" },
  { path: '/cms/pages/menu/edit/:languageShortName/:menuId', name: 'EditMenu', component: () => import(/* webpackChunkName: "Pages" */ '@/components/admin/page/menu/EditMenu.vue'), meta: "reqLogin" },
  { path: '/cms/pages/menu/add/:languageShortName', name: 'AddMenu', component: () => import(/* webpackChunkName: "Pages" */ '@/components/admin/page/menu/AddMenu.vue'), meta: "reqLogin" },
  /* Public */
  // { path: '/cms/pages/menu', name: 'Menu', component: () => import(/* webpackChunkName: "Pages" */ '@/components/admin/page/AllPages.vue') },

  /* Video */
  /* CMS */
  { path: '/cms/video/', name: 'Videos', component: () => import(/* webpackChunkName: "Videos" */ '@/components/admin/video/AllVideos.vue'), meta: "reqLogin" },
  { path: '/cms/video/add', name: 'AddVideo', component: () => import(/* webpackChunkName: "Videos" */ '@/components/admin/video/AddVideo.vue') },
  { path: '/cms/video/unpublished', name: 'UnpublishedVideos', component: () => import(/* webpackChunkName: "Videos" */ '@/components/admin/video/UnpublishedVideo.vue'), meta: "reqLogin" },
  { path: '/cms/video/edit/:videoId', name: 'EditVideo', component: () => import(/* webpackChunkName: "Videos" */ '@/components/admin/video/EditVideo.vue'), meta: "reqLogin" },
  { path: '/cms/video/processing/:videoId', name: 'ProcessingVideo', component: () => import(/* webpackChunkName: "Videos" */ '@/components/admin/video/ProcessingVideo.vue'), meta: "reqLogin" },
  { path: '/cms/video/pending/', name: 'PendingVideos', component: () => import(/* webpackChunkName: "Videos" */ '@/components/admin/video/PendingVideo.vue'), meta: "reqLogin" },
  { path: '/cms/video/deleted/', name: 'DeletedVideos', component: () => import(/* webpackChunkName: "Videos" */ '@/components/admin/video/DeletedVideo.vue'), meta: "reqLogin" },
  /* Public */
  { path: '/:lang/videos', name: 'AllVideosPublic', component: () => import(/* webpackChunkName: "Videos" */ '@/components/public/video/AllVideosPublic.vue') },
  { path: '/:lang/video/:videoId', name: 'Video', component: () => import(/* webpackChunkName: "Videos" */ '@/components/public/video/SingleVideo.vue') },
  
  /* Audio */
  /* CMS */
  { path: '/cms/audio/', name: 'AllAudio', component: () => import(/* webpackChunkName: "Audio" */ '@/components/admin/audio/AllAudio.vue'), meta : "reqLogin" },
  { path: '/cms/audio/add', name: 'AddAudio', component: () => import(/* webpackChunkName: "Audio" */ '@/components/admin/audio/AddAudio.vue'), meta: "reqLogin" },
  { path: '/cms/audio/edit/:audioId', name: 'EditAudio', component: () => import(/* webpackChunkName: "Audio" */ '@/components/admin/audio/EditAudio.vue'), meta: "reqLogin" },
  { path: '/cms/audio/pending/', name: 'PendingAudio', component: () => import(/* webpackChunkName: "Audio" */ '@/components/admin/audio/PendingAudio.vue'), meta: "reqLogin" },
  { path: '/cms/audio/unpublished/', name: 'UnpublishedAudio', component: () => import(/* webpackChunkName: "Audio" */ '@/components/admin/audio/UnpublishedAudio.vue'), meta: "reqLogin" },
  { path: '/cms/audio/deleted/', name: 'DeletedAudio', component: () => import(/* webpackChunkName: "Audio" */ '@/components/admin/audio/DeletedAudio.vue'), meta: "reqLogin" },
  /* Public */
  { path: '/:lang/audio', name: 'AllAudioPublic', component: () => import(/* webpackChunkName: "Audio" */ '@/components/public/audio/AllAudioPublic.vue') },
  { path: '/:lang/audio/:audioId', name: 'SingleAudio', component: () => import(/* webpackChunkName: "Audio" */ '@/components/public/audio/SingleAudio.vue') },
  
  /* Images */
  /* CMS */
  { path: '/cms/images/', name: 'Images', component: () => import(/* webpackChunkName: "Images" */ '@/components/admin/images/AllImages.vue'), meta: "reqLogin" },
  { path: '/cms/images/add/', name: 'AddImage', component: () => import(/* webpackChunkName: "Images" */ '@/components/admin/images/AddImages.vue'), meta: "reqLogin" },
  { path: '/cms/image/edit/:imageId', name: 'EditImage', component: () => import(/* webpackChunkName: "Images" */ '@/components/admin/images/EditImage.vue'), meta: "reqLogin" },
  { path: '/cms/images/unpublished/', name: 'UnpublishedImages', component: () => import(/* webpackChunkName: "Images" */ '@/components/admin/images/UnpublishedImages.vue'), meta: "reqLogin" },
  { path: '/cms/images/pending/', name: 'PendingImages', component: () => import(/* webpackChunkName: "Images" */ '@/components/admin/images/PendingImages.vue'), meta: "reqLogin" },
  { path: '/cms/images/deleted/', name: 'DeletedImages', component: () => import(/* webpackChunkName: "Images" */ '@/components/admin/images/DeletedImages.vue'), meta: "reqLogin" },
  /* Public */
  { path: '/:lang/images', name: 'AllImagesPublic', component: () => import(/* webpackChunkName: "Images" */ '@/components/public/image/AllImagesPublic.vue') },
  { path: '/:lang/image/:imageId', name: 'Image', component: () => import(/* webpackChunkName: "Images" */ '@/components/public/image/SingleImage.vue') },
  

  /* Tags */
  /* CMS */
  { path: '/cms/tags/', name: 'Tags', component: () => import(/* webpackChunkName: "Pages" */ '@/components/admin/tag/AllTags.vue'), meta: "reqLogin" },
  { path: '/cms/tags/add', name: 'AddTag', component: () => import(/* webpackChunkName: "Pages" */ '@/components/admin/tag/AddTag.vue'), meta: "reqLogin" },
  { path: '/cms/tags/edit/:tagId', name: 'EditTag', component: () => import(/* webpackChunkName: "Pages" */ '@/components/admin/tag/EditTag.vue'), meta: "reqLogin" },
  { path: '/cms/tags/unpublished/', name: 'UnpublishedTags', component: () => import(/* webpackChunkName: "Pages" */ '@/components/admin/tag/UnpublishedTag.vue'), meta: "reqLogin" },
  { path: '/cms/tags/deleted/', name: 'DeletedTags', component: () => import(/* webpackChunkName: "Pages" */ '@/components/admin/tag/DeletedTags.vue'), meta: "reqLogin" },

  /* Statistics */  
  { path: '/cms/statistics', name: 'Statistics', component: () => import(/* webpackChunkName: "Pages" */ '@/components/admin/stat/Statistics.vue'), meta: "reqLogin" },
  { path: '/cms/statistics/download', name: 'DownloadStatistic', component: () => import(/* webpackChunkName: "Pages" */ '@/components/admin/stat/DownloadStatistic.vue'), meta: "reqLogin"},

  /* Pages - public */
  { path: '/:lang/:page', name: "PublicPage", component: () => import(/* webpackChunkName: "Pages" */ '@/components/public/page/SinglePage.vue') }
]
// console.log(process.env.NODE_ENV);
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

export default router

