<template>
  <svg :width="width" :height="height" viewBox="0 0 54 72" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M46.6105 34.1055C46.6105 45.4739 36.9853 53.4318 26.5263 53.4318C16.0674 53.4318 6.4421 45.4739 6.4421 34.1055H0C0 47.0276 10.3074 57.7139 22.7368 59.5707V72.0002H30.3158V59.5707C42.7453 57.7518 53.0526 47.0655 53.0526 34.1055H46.6105Z" fill="#9ADCDA"/>
  <rect x="15" width="23" height="45" rx="11.5" fill="#005B70"/>
  </svg>
</template>

<script>
export default {
  name: "CustomAudioIcon",
  props: {
    width: String,
    height: String
  }
}
</script>
<style></style>
