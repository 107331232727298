<template>
  <b-nav-form>
    <b-overlay :show="showOverlay">
      <b-dropdown size="sm" variant="link" id="langChooser" no-caret v-if="selectedLang.name"> 
         <template #button-content>
           <span id="selectedLangDisplay">
             <b-avatar variant="info" :src="selectedLang.icon" size="1.75rem" class="mr-2"></b-avatar>
             <span class="mr-auto">{{ selectedLang.name.substring(0, 3) }}</span>
           </span>
           <svg class="car" style="margin-top: -2px; margin-left: 5px;" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 1L6 6L1 1" stroke="#35C0C0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
         </template>
         <b-dropdown-item @click="localeSwitch(l)" v-for="l in languages" :key="l.shortName">
           <b-avatar variant="info" :src="l.icon" size="1.75rem" class="mr-1"></b-avatar> {{l.name}}
         </b-dropdown-item>
       </b-dropdown>
    </b-overlay>
  </b-nav-form>
  
</template>
<script>
import { BDropdown, BAvatar } from "bootstrap-vue"

export default {
  name : "LocaleChooser",
  components: { BDropdown, BAvatar },

  data: function() {
    return {
      languages: [],
      showOverlay: false
    }
  },
  created: async function() {
    // console.log(this.selectedLang);
    await this.$http.get("/api/lang/all").then(r => {
      this.languages = r.data;
      var myLang = this.$root.lang;
      if (r.data.length > 0) {
        for (var i = 0; i < r.data.length; i++) {
          if (r.data[i].IANA === myLang.IANA) {
            this.selectedLang = r.data[i];
            break;
          }
        }
      }
    }).catch(e => {
      this.handleError(e);
    })
  },
  methods: {
    localeSwitch: function(l) {
      if (this.$i18n.locale !== l.IANA) {
        this.selectedLang = l;
        this.$root.$emit("languageChangeEvent", l);
        //window.location.href = '/';
      }
    }
  },
  computed: {
    selectedLang : { 
      get: function() {
        return this.$root.lang;
      },
      set: function(myLang) {       
      }
    }
  }
}
</script>

<style></style>
