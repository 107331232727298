<template v-if="languageLoaded">
  <div id="app">
    <Header :user="$root.user"></Header>
    <div id="content">
      <transition name="slide-fade" mode="out-in">
        <router-view :key="$route.fullPath" />
      </transition>
    </div>
    <Footer></Footer>
    <AcceptCookies />
  </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import AcceptCookies from "@/components/util/AcceptCookies.vue"

export default {
  name: 'App',
  components: { Header, Footer, AcceptCookies },

  data() {
    return {
      infoData: {},
      acceptCookies: false,
      languageLoaded: false
    }
  },

  async created() {
    
    // Prvo postavljamo jezik. Jezik možemo da imamo u URL-u, da ga korsinik
    // selektuje iz padajućeg menija, da bude izabran u profilu ili da bude u lokalnom storage-u
    // Glavni parametar za jezik je u url-u.
    let myLang = null;
    let langShortName = "";
    // Prvo proverimo da li imamo jezik u URL-u.
    // .filter(Boolean) koristimo da izbacimo prazne stringove, null i
    // false vrednosti
    const pathParts = document.location.pathname.split("/").filter(Boolean);
    // Ako postoji neka putanja koja nije naslovna strana.
    if (pathParts.length > 0 && pathParts[0] !== "cms") {
      // Skraćenica za jezik se nalazi u prvom članu niza.
      langShortName = pathParts[0];
    } else {
      // Ako je otvorena naslovna stranica bez jezika samo sa /
      // Proverićemo da li jezik postoji u windows localStorage-u
      if (window.localStorage.getItem("dmn_sl") !== null) {
        langShortName = this.decodeJSON(
                  window.localStorage.getItem("dmn_sl")
              );
      } else {
        langShortName = "en";
      }
    }

    if (window.sessionStorage.getItem("dmn_lng") !== null) {
      // console.log("Postavljen jezik iz sesije");
      myLang = this.decodeJSON(window.sessionStorage.getItem("dmn_lng"));
      this.setNewLanguage(myLang);

    } else {
      this.$http.get("/api/lang/getshort/" + langShortName).then(r => {
        // console.log("Dobavljam jezik sa servera");
        myLang = r.data;
        this.setNewLanguage(myLang);
        //console.log("Jezik je postavljen: " + myLang.IANA);
      }).catch(e => { this.handleError(e); })
    }

   },

  mounted() {
    this.$root.$on("languageChangeEvent", function (newLang) {
      //console.log("Global event lang change: " + this.$root.lang.IANA);
      this.lang = newLang;
      this.setNewLanguage(newLang);
    })
  }

}
</script>
<style></style>
