import Vue from 'vue'
import App from './App.vue'
import Axios from 'axios'
import router from './router'
import i18n from './i18n'
import VueGtag from "vue-gtag"
import VueMeta from 'vue-meta'

/* Jquery stuff */
import $ from 'jquery'
import Ripple from './assets/libs/jquery-ripple/jquery.ripples.js'

/* Bootstrap vue */
import {
  LayoutPlugin, ModalPlugin, AlertPlugin, ButtonPlugin, NavbarPlugin, FormInputPlugin,
  FormGroupPlugin, ListGroupPlugin, FormPlugin, OverlayPlugin, LinkPlugin
} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/main.css'
import './assets/main-media-query.css'
window.jQuery = window.$ = $;


Vue.use(LayoutPlugin)
Vue.use(ModalPlugin)
Vue.use(AlertPlugin)
Vue.use(ButtonPlugin)
Vue.use(NavbarPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormGroupPlugin)
Vue.use(ListGroupPlugin)
Vue.use(FormPlugin)
Vue.use(OverlayPlugin, { BOverlay: {variant : "light", blur: "3px", rounded: "md", "no-fade" : "true"}})
Vue.use(LinkPlugin)
Vue.use(i18n)
Vue.use(VueGtag, {
  config: { id: "G-G3YPMSV309" }
}, router);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.prototype.$http = Axios;

Object.defineProperty(Vue.prototype, '$ripple', { value: Ripple });

Vue.mixin({
  methods: {

    getAuthData() {
      let authData = false;
      if (window.localStorage.getItem("AccessToken") !== null) {
        authData = {};
        try {
          authData.accessToken = window.localStorage.getItem("AccessToken");
          authData.email = window.localStorage.getItem("Email");
          authData.accessTokenExpire = decodeURIComponent(escape(window.atob(window.localStorage.getItem("AccessTokenExpire"))));
        } catch (e) {
          //this.removeAuthData();
          //this.$router.push({ name: "Login", params: { message: i18n.t("signin.session-expired") }});
        }
      }
      //console.log("Auth data je: ");
      //console.log(authData);
      return authData;
    },

    removeAuthData() {
      // console.log("Brišem korisničke podatke.");
      window.localStorage.removeItem("AccessToken");
      window.localStorage.removeItem("Email");
      window.localStorage.removeItem("AccessTokenExpire");
      window.localStorage.removeItem("UserData");
      window.localStorage.removeItem("dmn_user");
      document.cookie = 'Authorization=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      this.$root.user = null;
    },

    isUserLoggedIn() {
      if (window.localStorage.getItem("dmn_user") === null) {
        //this.$router.push({ name: "Login", params: { message: i18n.t("signin.session-expired") } });
      };
    },

    handleError: function(error) {
      // console.log(error.response.data.error);
      let message = "";
      const vm = this;
      
      if (typeof error.response !== "undefined") {
        // Unauthorised
        if (error.response.status === 401) {
          this.removeAuthData();
          message = i18n.t("signin.bad-credentials");
          // Ako je statusni tekst "Unauthorized" i tu je istekla sesija.
          if (error.response.data.error === "Unauthorized") {
            message = i18n.t("signin.session-expired") + ".";
          }
          if (error.response.statusText === "Unauthorized") {
            message = i18n.t("signin.session-expired") + ".";
          }

          this.$router.push({name : "Login",  params: { lang: this.$root.lang.shortName, message: message }});
        }

        // Forbidden
        if (error.response.status === 403) {
          this.removeAuthData();
          message = i18n.t("signin.session-expired");
          this.$router.push({name : "Login",  params: { message: message }});
        }

        // Not found
        if (error.response.status === 404) {
          message = i18n.t("general.not-found-error")
        }

        // Server boo-boo
        if (error.response.status >= 500) {
         message = i18n.t("general.server-error")
         // Server error. Please try again later!"
        }
      } else {
        message = i18n.t("general.connection-error");
      }

      return {type: "danger", message: message};
    },

    encodeJSON: function(jsonObject) {
      return window.btoa(unescape(encodeURIComponent(JSON.stringify(jsonObject))));
    },

    decodeJSON: function(str) {
      return JSON.parse(decodeURIComponent(escape(window.atob(str))));
    },

    /**
     * @deprecated
     * @param fileInput 
     * @param thumbnailPlaceholder 
     */
    showMyImage: function(fileInput, thumbnailPlaceholder) {
      // console.log(fileInput, thumbnailPlaceholder);
      const file = fileInput;
      if (file !== null) {
        const imageType = /image.*/;
        if (file.type.match(imageType)) {
          thumbnailPlaceholder.file = file;
          const reader = new FileReader();
          reader.onload = (function(aImg) {
              return function(e) {
                  aImg.src = e.target.result;
              };
          })(thumbnailPlaceholder);
          reader.readAsDataURL(file);
        } else {
          console.log(file);
        }
      }
    },

    getFileSize: function (sizeInBytes) {
      let size = sizeInBytes / 1024;
      let unit = "KB";
      if (size > 1024.00) {
        size = size / 1024
        unit = "MB";
      }
      if (size > 1024.00) {
        size = size / 1024
        unit = "GB";
      }
      if (size > 1024.00) {
        size = size / 1024
        unit = "TB";
      }
      return +size.toFixed(2) + " " + unit;
    },

    getBitRate: function (sizeInBytes) {
      let size = sizeInBytes / 1024;
      let unit = "Kb/s";
      if (size > 1024.00) {
        size = size / 1024
        unit = "Mb/s";
      }
      if (size > 1024.00) {
        size = size / 1024
        unit = "Gb/s";
      }
      if (size > 1024.00) {
        size = size / 1024
        unit = "Tb/s";
      }
      return +size.toFixed(2) + " " + unit;
    },

    generateUrl: function (str) {
      if (str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();
        var from = "àáäâèéëêìíïîòóöôùúüûñçčćšđž·/_,:;?абвгдђежзијклмнњопрстћуфхцчџш";
        var to   = "aaaaeeeeiiiioooouuuuncccsdz-------abvgddezzijklmnnoprstcufhccds";
        for (var i = 0, l = from.length; i < l; i++) {
          str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }
        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                  .replace(/\s+/g, '-') // collapse whitespace and replace by -
                  .replace(/-+/g, '-'); // collapse dashes
        // console.log(str);
      }
      return str;
    },

    formatMessages: function(messagesJSONDatabse) {
      console.log(messagesJSONDatabse);
    },

    dateTime: function(dateString, size) {
      // console.log(dateString);
      // size - short, medium, long, full
      const myDate = Date.parse(dateString);
      if (!isNaN(myDate)) {
        const myFormatedDate = new Intl.DateTimeFormat(i18n.locale, { dateStyle: size, timeStyle: size }).format(myDate);
        return myFormatedDate;
      } else {
        return "";
      }
    },

    setNewLanguage: function(language) {
      // Uzmemo string poruka
      var langMessages = language.messages;
      
      // Parsiram string u JSON
      var messages = JSON.parse(langMessages);

      // myMessages - parsirane poruke u odgovarajućem formatu
      var myMessages = {};

      // Za svaku poruku kreiramo poseban JSON objekat preko niza.
      for (var i = 0; i < messages.length; i++) {
        var messageGroup = messages[i];
        var myMessageGroup = {};
        for (var j = 0; j < messageGroup.messages.length; j++) {
          myMessageGroup[messageGroup.messages[j].messageKey] = messageGroup.messages[j].message;
        }
        myMessages[messageGroup.name] = myMessageGroup;
      }
      
      // Postavimo skraćenicu u locale storage
      window.localStorage.setItem("dmn_sl", this.encodeJSON(language.shortName));

      // Postavimo ceo jezik u session storage
      window.sessionStorage.setItem("dmn_lng", this.encodeJSON(language));

      // Vue i18n
      this.$i18n.locale = language.IANA;
      this.$i18n.setLocaleMessage(language.IANA, myMessages);

      // Root globalne varijable
      this.$root.langShortName = language.shortName;
      this.$root.lang = language;
      //console.log("Postavljen je jezik: " + language.shortName);
    },

    secondsToTime: function(e) {
      var h = Math.floor(e / 3600).toString().padStart(2, '0');
      var m = Math.floor(e % 3600 / 60).toString().padStart(2, '0');
      var s = Math.floor(e % 60).toString().padStart(2, '0');
      return h + ':' + m + ':' + s;
    },

    tinyInitInline: function() {
      return {
        selector: ".tinyInline",
        menubar: false,
        inline: true,
        plugins: 'lattag',
        toolbar: "lattag",
        valid_elements: 'lat',
        paste_as_text: true,
        content_style: "lat {background:#9ADCDA; color:#07253E; padding: 1px 0;}"
      }
    },

    tinyInit: function(hegith = 350) {
      return {
        selector: ".tiny",
        height: hegith,
        menubar: false,
        entity_encoding: 'raw',
        plugins: 'autolink link lattag image media code charmap lists paste',
        toolbar: 'undo redo | bold italic | cut copy paste | lattag | alignleft aligncenter alignright alignjustify | bullist numlist | link image media | charmap | code',
        branding: false,
        statusbar: false,
        paste_as_text: true,
        contextmenu: "link cut copy paste",
        extended_valid_elements : "" +
        "a[name|href|target|title|onclick|rel|class|data-width|data-align]," +
        "img[class|src|border=0|alt|title|hspace|vspace|width|height|align|onmouseover|onmouseout|name|style]," +
        "span[class|align|style|onmouseover|onmouseout]," +
        "+iframe[src|width|height|name|align|frameborder|allowfullscreen|scrolling|allowTransparency|allow|style|srcdoc|class]," +
        "+table[id|class|align|style]," +
        "+tr[id|class|style],td[id|class|style]," +
        "+blockquote[class|lang]," +
        "lat",
        content_style: "body {font-family: 'Inter', sans-serif; font-size: 1rem; font-weight: 500; color: #07253E; font-size: 1rem; margin: .5rem;}" +
                       "p {margin: 0 0 8px 0;}" +
                       "lat {background:#9ADCDA; color:#07253E; padding: 1px 0;}"
      }
    },

    /*
    * Insert lat tag-a u input polje za naslov
    */
    insertLat: function (element) {
      // console.log(element);
      const elem = document.getElementById(element);
      const start = elem.selectionStart;
      const end = elem.selectionEnd;
      // console.log(start, end)
      // Ako ništa nije označneno, ništa ne bi trebalo da se desi.
      if (start < end) {
        const selectedText = elem.value.substring(start, end)
        let textToReplace = "";
        textToReplace = "<lat>" + selectedText + "</lat>";
        const newTextValue = elem.value.substring(0, start) + textToReplace + elem.value.substring(end);
        // console.log(newTextValue);
        elem.value = newTextValue;
        this.media.title = newTextValue
      }
    },

    getFileName: function(path) {
      if (path != null && path.length > 0) {
        const fileName = path.split('\\').pop().split('/').pop();
        return fileName;
      } else {
        return '';
      }
    }
  }
})


Vue.config.productionTip = false

Vue.filter('abb', function (text, stop, clamp) {
  return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
})

Vue.filter('removeLat', function(text) {
  if (text) {
    return text.replace(/<lat>/g, "").replace(/<\/lat>/g, "");
  }
});

/** 
 * Provera da li postoji AccessToken pri otvaranju svake stranice
 * koja zahteva projavu (reqLogin meta data)
 */
router.beforeEach(function(to, from, next) {
/*   console.log("To parametar: ")
  console.log(to);
  console.log(" ");
  console.log("From parameter");
  console.log(from); */
  // console.log(document.getElementById("searchHeader"));
  
  if (document.getElementById("searchHeader") !== null) {
    document.getElementById("sbx").style.opacity = "0";
    document.getElementById("sbx").style.zIndex = "9";
    document.getElementById("searchHeader").style.opacity = "0";
    document.getElementById("searchHeader").style.width = "0px";
    document.getElementById("searchHeader").style.left = "30px";
    document.getElementById("searchHeader").style.boxShadow = "none";
    document.getElementById("searchHeader").style.padding = "0";
    document.getElementById("shr").style.display = "none";
    document.getElementById("shb").style.opacity = 1;
    // this.searchTerm = "";
  }

  if (to.meta === "reqLogin") {
    // console.log(to.path);
    const accessTokenExpire = window.localStorage.getItem("AccessTokenExpire");
    if (accessTokenExpire === null) {
      const selectedLang = JSON.parse(window.atob(window.localStorage.getItem("dmn_sl")));
      next({ name: "Login", params: { lang: selectedLang, message: ""}});
    } else {
      next();
    }
  } else {
    next();
  }
})

new Vue({
  router,
  i18n,
  data: {
    lang : {}
  },
  render: h => h(App)
}).$mount('#app')

