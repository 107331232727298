<template>
   <svg :width="width" :height="height" 
    viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27 52.1954V19.8145L52 36L27 52.1954Z" fill="#005B70"/>
    <circle cx="36" cy="36" r="32.5" stroke="#9ADCDA" stroke-width="7"/>
  </svg>
</template>

<script>
export default {
  name: "CustomVideoIcon",
  props: {
    width: String,
    height: String
  }
}
</script>
<style></style>
