<template>
    <div v-if="!acceptCookiez" class="acceptCookies p-1">
      <transition name="slide-fade" mode="out-in" :appear="true">
      <div class="acceptCookiesPanel p-4 m-2">
        <div class="mb-3">{{$t('general.accept-cookies-text')}}</div>
        <b-button variant="primary" @click="accept"> {{ $t('general.yes-accept-cookies')}} </b-button>
      </div>
      </transition>
    </div>
</template>

<script>
export default {
  name: "AcceptCookies",
  data: function() {
    return {
      acceptCookiez: false
    }
  }, 
  components: {},
  created: function() {
    this.acceptCookiez = window.localStorage.getItem("AcceptedCookies");
  },
  methods: {
    accept: function (params) {
      this.acceptCookiez = true;
      window.localStorage.setItem("AcceptedCookies", true)
    }
  }
}
</script>

<style></style>
